@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.core();

$PeopleMate-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$PeopleMate-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$PeopleMate-warn: mat.m2-define-palette(mat.$m2-red-palette);
$PeopleMate-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $PeopleMate-primary,
      accent: $PeopleMate-accent,
      warn: $PeopleMate-warn,
    ),
  )
);

@include mat.all-component-themes($PeopleMate-theme);

:root {
  --primary-color: #5c2d91;
  --save-color: #5c2d91;
  --disabled-primary-color: #ded5e9;
  --grey-color: #74788d;
  --page-bg-color: #f8f8fb;
  --active-link-color: #b66fb4;
  --grey-active-company-color: #f3f3f9;
  --grey-icon-color: #555b6d;
  --label-color: #495057;
  --danger-color: #cb393b;
  --place-holder-color: #a6a6a6;
  --paragraph-color: #5c5673;
  --side-menu-subtitle-color: rgba(255, 255, 255, 0.6);
  --breadcrumb-link-color: #4d4d4d;
  --breadcrumb-label-color: #6b7a88;
  --list-label-number-color: #b66fb4;
  --input-border-color: #ced4da;
  --table-border-color: #eff2f7;
  --green-color: #307456;
  --form-border-color: rgba(0, 0, 0, 0.13);
  --tabsBG: #c8c9d01a;
  --tab-border: #077fa31a;
  --warning-border: #fbe9c9;
  --warning-border2: #e6ad0b;
  --error-border: rgba(203, 57, 59, 0.25);
  --warning-font-color: #663c00;
  --warning-bg-color: #ded5e9;
  --snackBar-error-bg: #f7e1e2;
  --snackBar-warning-bg: #fbf3da;
  --snackBar-success-bg: #ddf1e8;
  --grey-BG: rgba(206, 212, 218, 0.25);
  --notifications-item-bg: #ededed;

  --mat-form-field-container-vertical-padding: 8px;
  --mat-datepicker-calendar-container-shape: 12px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-height: 48px;
  --mat-menu-container-shape: 8px;
}

@import "@ng-select/ng-select/themes/default.theme.css";
@import "./assets/scss/0-base/base.dir.scss";
@import "./assets/scss/1-components/components.dir.scss";
@import "./assets/scss/3-pages/pages.dir.scss";

@import "leaflet/dist/leaflet.css";

app-paginated-select {
  width: 100%;
}

.we-dashboard-menu {
  width: 160px !important;
  height: 66px !important;
  object-fit: contain !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-form-field {
  --mdc-outlined-text-field-container-shape: 8px;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

.mat-mdc-menu-item,
.mat-mdc-option {
  border-radius: 12px !important;
  margin: 2px 5px !important;
}

div.mat-mdc-select-panel {
  border-radius: 12px !important;
  margin-top: 4px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  padding: 4px 0 !important;
}

.mat-mdc-menu-panel,
.mat-datepicker-content,
div.mat-mdc-select-panel {
  margin-top: 4px !important;
}

.mat-mdc-menu-content {
  padding: 4px !important;
}

.mat-mdc-select {
  margin-top: 5px !important;
}

.mat-mdc-dialog-content {
  max-height: 80vh !important;
}
